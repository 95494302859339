.App {
  text-align: center;
}

* {
  box-sizing: border-box !important;
}

i {
  font-size: 1.5rem;
  padding: 0.4rem;
}

.navbarIcon i {
  margin-left: 2rem;
  margin-right: 2rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




body:scrollbar-width{
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

};

body::-webkit-scrollbar{

  width: 0; /* For Chrome, Safari, and Opera */
  display: none;

}
/* Hide vertical scrollbar for WebKit-based browsers */
/* * {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; 
  -ms-overflow-style: none; 
} */

/* *::-webkit-scrollbar {
  width: 0;
} */

/* Ensure content is still scrollable */
/* body,
html {
  overflow-x: auto;
  overflow-y: scroll;
} */

/* .custom-scrollbar {
  overflow-x: auto;
  white-space: nowrap;
} */

/* Custom scrollbar styles (optional) */
/* .custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0px;
} */

/* .custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

/* Custom scrollbar styles for Firefox */
/* .custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
} */

/* Ensure vertical scrollbar is hidden */
/* body::-webkit-scrollbar {
  width: 0px;
}
body {
  scrollbar-width: none; 
} */




.css-136h9lv-MuiFormControl-root-MuiTextField-root {
  overflow: visible !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  line-height: 2.4rem !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color:"red";

  font-size: 1.1rem;
  font-weight: 500;
  line-height:4rem;
}

.css-1x5jdmq {
  height: auto !important;
}

.css-p0rm37P{
  line-height: 2.5rem !important;
}

label {
  line-height: 2.4rem !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  height: 3.475rem !important;
}

/* Working With Table in App  */

/* .css-orq5n1-MuiButtonBase-root-MuiTableSortLabel-root{
  color:black !important;
 font-Size:"2.3rem";
} */


.css-f7t77y .MuiOutlinedInput-root {
  padding: 26px !important;
}

.css-14lo706{
  height: auto !important;

}

/* .css-14lo706>span{
  opacity: 1 !important;
} */


/* Global CSS to hide vertical scrollbars */




/* Custom class to show vertical scrollbars */
.custom-scrollbar {
  overflow-y: auto; /* Enables vertical scrolling */
  scrollbar-width: thin; /* For Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
  display: block;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #a22727; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #f32a2a; /* Scrollbar thumb color on hover */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track background color */
}


.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  height: 3.4375em !important
}